<template>
  <CBox
    bg="white"
    minH="300px"
    borderRadius="10px"
    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
  >
    <CBox minH="60px">
      <CBox py="40px" px="20px">
        <CText
          whiteSpace="wrap"
          mb="10px"
          fontFamily="Montserrat"
          :fontSize="['20px', '24px', '34px', '36px']"
          fontWeight="bolder"
          color="fontMain"
        >
          Connect With Telegram
        </CText>
        <CBox v-if="!currentUser && !isActivated" px="10px" py="10px" borderRadius="4px" bgColor="bgFailedState">
          <CText
            display="inline"
            fontFamily="Montserrat"
            color="failedColor"
            fontWeight="bold"
            fontSize="14px"
          >
            You must login first to connect with telegram
          </CText>
          <CText
            v-if="!currentUser && !isActivated"
            display="inline"
            fontFamily="Montserrat"
            color="failedColor"
            fontWeight="bold"
            fontSize="14px"
          >
            ,<span
              style="color: #29aafe; cursor: pointer"
              @click="redirectToSignIn"
            >
              Click here</span>
            to login
          </CText>
        </CBox>
        <CBox v-else-if="isActivated" px="10px" py="10px" borderRadius="4px" bgColor="bgSuccessState">
          <CText
            display="inline"
            fontFamily="Montserrat"
            color="successColor"
            fontWeight="bold"
            fontSize="14px"
          >
            You have connected with Telegram, please 
            <span
              style="color: #29aafe; cursor: pointer"
              @click="redirectToTelegram"
            >
              Click here
            </span>
            to return to Telegram
          </CText>
        </CBox>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import config from "@/ConfigProvider";
import { POST_TELEGRAM_ACTIVATION } from "@/store/telegram.module";

export default {
  data() {
    return {
      isActivated: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    redirectToSignIn() {
      window.location.href =
        config.value("GETHIRED_WEB_URL") +
        `/signin?next=${window.location.href}`;
    },
    redirectToTelegram() {
      window.location.href = "https://t.me/DevcodeaiBot";
    },
    activateTelegram() {
      const token = this.$route.params.slug;
      this.$store
        .dispatch(POST_TELEGRAM_ACTIVATION, token)
        .then(() => {
          console.log("Activation successful");
          this.isActivated = true;
        })
        .catch((error) => {
          console.error("Activation failed", error);
        });
    },
  },
  mounted() {
    if (this.currentUser) {
      this.activateTelegram();
    }
  },
};
</script>